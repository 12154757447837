/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


// global.scss
@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';
@import 'swiper/scss/zoom';

.section-header{
    background-color:#ff470c;
    img{
        border-radius: 10px;
    }
    p{
        color:white;
    }
}

.store-details-container{
    p {
        margin: 0px;
        font-size: 13px;
        margin-bottom: 4px;
        font-weight: bold;
    }
    .store-name{
        font-weight: bolder;
        font-size: 20px;
    }
    .store-description{
        text-align: center;
        margin: 0;
    }
}


.box-shadow{
    box-shadow: 0px 0px 0px 2px #33333312;
}

p.back-button {
    margin: 0px 0px 5px;
    ion-icon {
        position: relative;
        top: 2px;
    }
}

hr {
    height: 1px;
    border-width: 0;
    box-sizing: content-box;
    background: #00000024;
    margin: 10px 0px;
}

.alert-box {
    background: #FAFAFA;
    padding: 15px;
    text-align: center;
    font-size: 18px;
    border-radius: 5px;
    p {
        margin: 0px;
    }
    &.alert-box-success {
        background: #16a085;
        color: white;
    }
}

h2.heading {
    background: #f3f3f3;
    padding: 10px;
    font-size: 18px;
    margin: 10px 0px;
    text-transform: uppercase;
    border-left: 5px solid #fe470d;
}

h3 {
    font-size: 18px;
}

.badge-status {
    font-size: 10px;
    text-transform: uppercase;
    &.badge-status-approved {
        background: #2ecc71;
    }
    &.badge-status-disapproved {
        background: #e74c3c;
    }
    &.badge-status-pending_approval {
        background:#7f8c8d;
    }
}

ion-back-button {
    color: white;
}
// ion-header ion-title {
//     background: #ff470c;
//     color: white;
// }

p.store-short-description {
    margin-bottom: 0px;
}

.logo-container{
    text-align: center;
    background: #ff470c;
    .logo {
        filter: brightness(0) invert(1);
        max-width: 50%;
    }
}

div#main-content {
    background: #eeeeee;
}


.heading-padding h2 {
    background: #fe460d;
    color: white;
    padding: 10px 20px;
    font-size: 17px;
    margin: 15px;
    border-radius: 5px;
}

ion-toggle {
    height: 10px;
    width: 65px;
  
    padding: 12px;
  
    --handle-width: 25px;
    --handle-height: 27px;
    --handle-max-height: auto;
    --handle-spacing: 6px;
  
    /* Required for iOS handle to overflow the height of the track */
    overflow: visible;
    contain: none;
  }
  
  ion-toggle::part(track),
  ion-toggle.toggle-checked::part(track) {
    background: #ddd;
  }
  
  ion-toggle::part(handle) {
    background: #eb7769;
  
    border-radius: 4px;
    box-shadow: none;
  }
  
  ion-toggle.toggle-checked::part(handle) {
    background: #95c34e;
  }


* {
    box-shadow: none!important;
}

ion-label.form-label {
    opacity: .45;
}

.order-stages-container{
    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        margin-top: 23px;
    }
    ul li {
        background: #FAFAFA;
        padding: 15px 15px;
        margin-bottom: 5px;
        text-align: center;
    }
    ul li.active{
        background-color:#2ecc71;
        color:white;
    }
    ion-icon {
        position: relative;
        top: 2px;
    }
}

p.ion-text-center.version-text {
    font-size: 8px;
    text-transform: uppercase;
    opacity: .76;
}

ion-item{
    ion-label[position] {
        color: #fe460d!important;
        font-weight: bold;
    }
}

ion-input[readonly],
ion-textarea[readonly] {
    background: #eeeeee;
}

ul.list-group {
    padding: 0px;
    margin: 0px;
}

ul.list-group li {
    width: 50%;
    display: inline-block;
    /* font-size: 18px; */
    /* background: #fe470d; */
    /* color: white; */
}

ul.list-group li a {
    font-size: 18px;
    background: #fe470d;
    color: white;
    text-decoration: none;
    font-size: 13px;
    display: block;
    margin: 5px;
    border-radius: 5px;
    padding: 8px;
    text-align: center;
    border: 1px solid #d4d4d4;
    font-weight: bolder;
}

// app-footer-tabs * {
//     background: #fe460d;
//     color: white;
// }

.middle
{
     display: flex!important;
     align-content: center!important;
     align-items: center!important;
}

.pre-line {
    white-space: pre-line;
}
.alert-message {
    white-space: pre-line;
}

.preparing-container {
    margin: 10px;
    background: white;
    padding: 15px;
    font-size: 17px;
    border-left: 6px solid #ff470c;
    font-size: 13px;
}
.delivery-restriction-container {
    margin: 10px;
    background: white;
    padding: 15px;
    font-size: 17px;
    border-left: 6px solid #ff470c;
    font-size: 13px;
}
.cart-empty-container {
    text-align: center;
    margin-top: 100px;
    text-transform: uppercase;
}

span.pull-to ion-icon {
    margin-right: 5px;
    position: relative;
    top: 2px;
}
span.pull-to {
    display: block;
    background: #FAFAFA;
    padding: 11px;
    border: 1px solid #00000054;
    font-weight: bold;
}

.section-home-grid .promotion-discount-note-text-container {
    font-size: 8px!important;
    padding: 5px!important;
}

@media print {
    ion-menu, .no-print {
       display:none;
    }
 }

 app-footer-tabs {
    background: #ff470c;
}
ion-item-divider ion-label {
    font-size: 16px !important;
    color: #ff470c !important;
    font-weight: bolder;
}

.sidebar-menu {
    ion-item-divider ion-label {
        color: white !important;
    }
}

.no-padding{
    padding:0;
}
.my-auto{
    margin-top:auto;
    margin-bottom:auto;
}

.w-100{
    width:100%;
}


h2.header {
    background: #f3f3f3;
    padding: 10px;
    font-size: 21px;
    font-weight: bold;
    border-left: 4px solid #ff470c;
    color: #ff470c;
}
ion-badge.cargo-request-status {
    width: 100%;
    padding: 10px;
    font-size: 20px;
    border-radius: inherit;
}


ion-badge.requested{
    background-color: grey;
}
ion-badge.pending_payment{
    background-color: orange;
}
ion-badge.confirmed{
    background-color: blue;
}
ion-badge.completed{
    background-color: green;
}
ion-badge.canceled{
    background-color: red;
}

// requested, pending_payment, confirmed, completed, canceled

.pickup-request-container{
    p{
        margin:0;
    }
}